import moment from "moment";
import React, { Fragment } from "react";

// Icons
import { AiOutlinePhone } from "@react-icons/all-files/ai/AiOutlinePhone";

// Components
import { Heading, HeroImage, HorizontalDividerCard } from "@components";

// Images
import ServicePlaceholder from "@images/placeholder.png";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ServiceDetails = ({ pageContext }) => {
    const { name, phone, image, description, openingHours } = pageContext;
    return (
        <>
            <HeroImage
                title={name}
                image={image?.localFile?.childImageSharp?.fluid}
            />

            <div className="max-w-1280 mx-auto py-5 px-3 xl:px-0 flex flex-col md:flex-row justify-between gap-x-8">
                <div className="w-full md:w-1/2 pt-3">
                    <Heading size={"xl"}>Opening hour</Heading>
                    {openingHours && openingHours.length > 0 ? (
                        openingHours?.map(
                            ({ id, name, opening, closing }, i) => (
                                <Fragment key={id}>
                                    <div className="w-full flex justify-between items-center py-1">
                                        <h4 className="text-black text-sm  font-normal mb-0">
                                            {name}
                                        </h4>
                                        <p className="text-black text-sm  font-semibold mb-0">
                                            {moment(opening, "hh:mm:ss").format(
                                                "h:mm a"
                                            )}{" "}
                                            -{" "}
                                            {moment(closing, "hh:mm:ss").format(
                                                "h:mm a"
                                            )}
                                        </p>
                                    </div>
                                    {i !== openingHours.length - 1 && (
                                        <HorizontalDividerCard
                                            colors={[
                                                "#FFFFFF00",
                                                "#7B61FF",
                                                "#FFFFFF00",
                                            ]}
                                        />
                                    )}
                                </Fragment>
                            )
                        )
                    ) : (
                        <div className="mt-10">
                            <AiOutlinePhone className="text-gray77 text-3xl mx-auto" />
                            <p className="text-base text-gray77 text-center  mt-2.5">
                                Please call for appointments
                            </p>
                        </div>
                    )}
                </div>

                {/*  */}
                <div className="w-full md:w-1/2 mt-5 md:mt-0">
                    {image && (
                        <GatsbyImage
                            image={getImage(image?.localFile)}
                            alt={image.alternativeText}
                            className="w-full h-52 object-cover rounded-xl shadow"
                        />
                    )}

                    {/*  */}
                    <p className="text-xl py-5">
                        Contact : <span className="text-sm"> {phone} </span>{" "}
                    </p>
                    <p> {description} </p>
                </div>
            </div>
        </>
    );
};

export default ServiceDetails;
